import { getLayout } from "components/layouts/landingSimpleLayout"

import { THEME } from "configs/theme"
import LoginPage from "@common/pages/loginPage"
import { getIsLoggedIn } from "@common/network"

Login.getLayout = getLayout

Login.getInitialProps = async (ctx) => {
  const cookie = ctx.req
    ? { withCredentials: true, headers: { cookie: ctx.req.headers.cookie } }
    : {}

  return await getIsLoggedIn(cookie)
}

export default function Login({ data }) {
  return <LoginPage data={data} siteConfig={THEME} logoVersion="icon" />
}
